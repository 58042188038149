import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Structure from '../Structure';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import ImageIcon from '@mui/icons-material/Image';
import { cerrarSesion } from '../../redux/actions/session';
import { validateEmail } from '../../lib/helpers/helpers';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { rutas } from '../../lib/data/rutas';

const TiposCuentaCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tipoVehiculo, setTipoVehiculo] = useState({});
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'titulo', label: 'Título' }
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    tipoVehiculo[name] = value;
    console.log(tipoVehiculo);
    return setTipoVehiculo({ ...{}, ...tipoVehiculo });
  };

  const crearTipoVehiculo = async () => {
    let faltantes = [];
    requeridos.map((campo) => {
      if (!tipoVehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    return fetch(`${data.urlapi}/tiposcuenta`, {
      method: 'POST',
      body: JSON.stringify(tipoVehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `${rutas.tipos_cuenta.slug}-${res._id}`);
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleImage = (e) => {
    console.log(e);
  };

  const render = () => {
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to={`${rutas.tipos_cuenta.slug}`}>{rutas.tipos_cuenta.title}</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear tipo de cuenta
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear</h1>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <Typography variant='h6'>Introduce la información</Typography>
        <CamposObligatoriosLeyenda />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Titulo'
              variant='filled'
              name='titulo'
              required={siEsRequeridoEnEsquema("titulo",requeridos)}
              fullWidth
              onChange={handleChange}
            />
          </Grid>

          <Grid item md={12} xs={12}>
            {loading === true ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                color='success'
                xs={{ m: 0 }}
                onClick={() => crearTipoVehiculo()}
              >CREAR</Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default TiposCuentaCreate;
