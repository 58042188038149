import { LoadingButton } from "@mui/lab"
import { Alert, CircularProgress, debounce, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"

const Impuestos = (props) => {
    const estructuraActual = {
        valor: 19
    }
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const dispatch      = useDispatch()
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const sesion        = useSelector((state) => state.miusuario)
    const showTitle     = props.showTitle ? props.showTitle : false
    const pais = useSelector(state => state.pais)
    const [ id, setId ] = useState("")
    const tipo          = "impuestos"
    const sub_tipo      = "valores"

    const handleChange = (e) => {
        const { name, value } = e.target
        return setConfiguracion(prev => {
            const actual = { ...prev }
            actual[name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const obtenerConfiguracion = async () => {
        setLoading(true);
        return fetch(`${urlapi}/configuracion/personalizar`, {
        method: 'POST',
        body: JSON.stringify({
            tipo,
            sub_tipo,
            detalles: estructuraActual
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoading(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoading(false);
            } else if (res._id) {
                setId(res._id)
                setConfiguracion(res.detalles)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }

    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    useEffect(() => {
        obtenerConfiguracion()
    }, [])

    if(loading) return <div><CircularProgress /></div>

    return <div>
        <Typography variant='h4' mt={0} ><b>Impuesto</b></Typography>
        <Typography >Configura el valor del impuesto del pais seleccionado</Typography>
        <Typography mb={2}>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>{ loadingGuardado ? <Alert severity="info"><b>GUARDANDO...</b></Alert> : <Alert severity="success"><b>GUARDADO</b></Alert> }</b> }</Typography>
        <Grid container spacing={1}>
            {/* <Grid item md={6} xs={12}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Tipo de profit"
                    sx={{ mb: 2 }}
                    fullWidth
                    name="type"
                    defaultValue={configuracion.type}
                    onChange={handleChange}
                    >
                    <MenuItem value="porcentaje">Porcentaje</MenuItem>
                    <MenuItem value="fijo">Monto fijo</MenuItem>
                </TextField>
            </Grid> */}
            <Grid item md={6} xs={12}>
                <TextField
                  label='Valor (%)'
                  variant='filled'
                  name='valor'
                  fullWidth
                  type="number"
                  defaultValue={configuracion.valor}
                  onChange={handleChange}
                />
              </Grid>
            <Grid item md={12} xs={12}>
            {/* <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loadingGuardado}
                    loadingPosition="start"
                    variant="outlined"
                    color="success"
                    onClick={() => actualizarConfiguracion()}
                    >
                     GUARDAR CAMBIOS
                </LoadingButton> */}
              </Grid>
        </Grid>
    </div>
}

export default Impuestos