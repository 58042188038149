import { LoadingButton } from "@mui/lab"
import { Alert, Button, Card, CircularProgress, debounce, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { FaMinusCircle, FaPlus } from "react-icons/fa";

const ExigenciasSolicitud = (props) => {
    const estructuraActual = {
        groups: [],
        options: []
    }
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const dispatch      = useDispatch()
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const sesion        = useSelector((state) => state.miusuario)
    const showTitle     = props.showTitle ? props.showTitle : false
    const pais = useSelector(state => state.pais)
    const [ id, setId ] = useState("")
    const tipo          = "solicitudes"
    const sub_tipo      = "exigencias"

    const handleChange = (e) => {
        const { name, value } = e.target
        return setConfiguracion(prev => {
            const actual = { ...prev }
            actual[name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const obtenerConfiguracion = async () => {
        setLoading(true);
        return fetch(`${urlapi}/configuracion/personalizar`, {
        method: 'POST',
        body: JSON.stringify({
            tipo,
            sub_tipo,
            detalles: estructuraActual
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoading(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoading(false);
            } else if (res._id) {
                setId(res._id)
                setConfiguracion(res.detalles)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }

    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    useEffect(() => {
        obtenerConfiguracion()
    }, [])

    const handleChangeGroup = (e, i) => {
        const { name, value } = e.target
        setConfiguracion(prev => {
            let actual = { ...prev }

            const options = actual.options.map(item => {
                if(item.group === actual.groups[i].key) {
                    item.group = value
                }
                return item
            })

            actual.groups[i][name]      = value
            actual.groups[i].key       = value
            actual.options              = options
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const handleChangeOption = (e, i) => {
        const { name, value } = e.target
        console.log(name, value, i)
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options[i][name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const remover = (i) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.groups.splice(i, 1)
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const removerOpcion = (i) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options.splice(i, 1)
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const mostrarOpciones = (group) => {
        if(!group) return null
        return <div>
            {
                configuracion.options.map((item, index) => {
                    if(item.group.toString().toLowerCase() !== group.toString().toLowerCase()) return null
                    return <div key={`e-${index}`}>
                            <Grid container spacing={1} justifyItems="center" alignItems="center" alignContent="center">
                            <Grid item xs={10}>
                            <TextField
                                label='Nombre de la opción'
                                variant='filled'
                                name='label'
                                mb={2}
                                value={item.label}
                                fullWidth
                                onChange={(e) => handleChangeOption(e, index)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                            <Button color="error" startIcon={<FaMinusCircle />} onClick={() => removerOpcion(index)} >REMOVER</Button>
                            </Grid>
                            </Grid>
                        </div>
                })
            }
        </div>
    }

    const mostrarSugerencias = () => {
        if(!configuracion.groups) return null
        if(!Array.isArray(configuracion.groups)) return null
        return <div>
            {
                configuracion.groups.map((item, index) => {
                    return <div key={`opt-${index}`}>
                        <Card style={{ padding: 10, marginBottom: 20 }}>
                        <Typography variant="h5"><b>{(index+1)}</b></Typography>
                            <Grid container spacing={1} justifyItems="center" alignItems="center" alignContent="center">
                            <Grid item xs={10}>
                            <TextField
                                label='Nombre del grupo'
                                variant='filled'
                                name='label'
                                mb={2}
                                value={item.label}
                                fullWidth
                                onChange={(e) => handleChangeGroup(e, index)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                            <Button color="error" startIcon={<FaMinusCircle />} onClick={() => remover(index)} >REMOVER</Button>
                            </Grid>
                            </Grid>
                            <Button style={{ marginBottom: 10 }} variant="contained" color="primary" sx={{ mt: 2 }} startIcon={<FaPlus />} onClick={() => agregarOpcion(item.key)} > AGREGAR OPCIÓN</Button>
                            {mostrarOpciones(item.key)}
                        </Card>
                        </div>
                })
            }
        </div>
    }

    const agregarOpcion = (group) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options.push({ group, label: "" })
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const agregarGrupo = () => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.groups.push({ value: "" })
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    if(loading) return <div><CircularProgress /></div>

    return <div>
        <Typography variant='h4' mt={0} ><b>Sugerencias en títulos de cotización</b></Typography>
        <Typography >Al generar la cotización, los proveedores pueden acceder a opciones preestablecidas para seleccionar en lugar de siempre escribir los títulos de los items.</Typography>
        <Typography mb={2}>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>{ loadingGuardado ? <Alert severity="info"><b>GUARDANDO...</b></Alert> : <Alert severity="success"><b>GUARDADO</b></Alert> }</b> }</Typography>
        <Grid container spacing={1}>
            {/* <Grid item md={6} xs={12}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Tipo de profit"
                    sx={{ mb: 2 }}
                    fullWidth
                    name="type"
                    defaultValue={configuracion.type}
                    onChange={handleChange}
                    >
                    <MenuItem value="porcentaje">Porcentaje</MenuItem>
                    <MenuItem value="fijo">Monto fijo</MenuItem>
                </TextField>
            </Grid> */}
            <Grid item xs={12}>
                <Button style={{ marginBottom: 10 }} variant="contained" color="primary" startIcon={<FaPlus />} onClick={() => agregarGrupo()} > AGREGAR GRUPO</Button>
                {mostrarSugerencias()}
              </Grid>
            <Grid item md={12} xs={12}>
              </Grid>
        </Grid>
    </div>
}

export default ExigenciasSolicitud