import { Box, Button, Chip, Grid, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState} from 'react'
import Structure from '../Structure'
import PropTypes from 'prop-types';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import ListadoUsuarios from './listado';
import { useSelector } from 'react-redux';
import MenuConfig from '../Configuracion/menu';
import ListadoUsuariosProveedores from '../Proveedores/Usuarios/listado';
import { Tag } from '@mui/icons-material';

const UsersClients = () => {
    const [value, setValue] = useState(0)
    const sesion = useSelector(state => state.miusuario)
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 3 }}>{children}</Box>
            )}
          </div>
        );
      }
      
      TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
      };
      
      function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }
      
    const handleChange = (e, value) => {
        return setValue(value)
    }

    const render = () => {

      return <div>
      <h1 style={{ margin: 0 }}>Configuración</h1>
      <Grid container spacing={3}>
        <Grid item md={3}>
          <MenuConfig />
        </Grid>
        <Grid item md={9}>
        <h1 style={{ margin: 0 }}>Usuarios <Chip label="PORTAL CLIENTE" /></h1>
            <ListadoUsuariosProveedores type_business="empresa-cliente" condicion_default={{  }} />
        </Grid>
      </Grid>
    </div>

        return <div>
            
        </div>
    }

    return <Structure component={render()} />
}

export default UsersClients