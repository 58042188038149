import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import Structure from '../Structure';
import { Link, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';

const RequisitosCreate = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [requisito, setRequisito] = useState({});
  const [loadingSelect, setLoadingSelect] = useState(true);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const [selectOptionsTipo, setSelectOptionsTipo] = useState([]);
  const [selectVehicle, setSelectVehicle] = useState(false);
  const sesion = useSelector((state) => state.miusuario);
  const requeridos = [
    { value: 'titulo', label: 'Titulo' },
    { value: 'descripcion', label: 'Descripción' },
    { value: 'tipo', label: 'Tipo' },
  ];
  
  const handleChange = async (e) => {
    setSelectVehicle(false);
    const { name, value } = e.target;
    requisito[name] = value;
    if (e.target.value === 'vehiculo') {
      setSelectVehicle(true);
      await obtenerTipos();
    } else {
      delete requisito.tipo_vehiculo;
      delete requisito.vehiculo_name;
    }
    return setRequisito({ ...{}, ...requisito });
  };

  const handleSelectTipo = (e) => {
    requisito.tipo_vehiculo = e.target.value.id;
    requisito.vehiculo_name = e.target.value.tipo;
    console.log(e.target);
  };

  const crearRequisito = async () => {
    console.log(sesion);
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!requisito[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoading(true);
    return fetch(`${data.urlapi}/requisitos/tipo`, {
      method: 'POST',
      body: JSON.stringify(requisito),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `/requisitos-${res._id}`);
          }, 500);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const obtenerTipos = async () => {
    return fetch(`${data.urlapi}/vehiculos/tipo/list/select`, {
      method: 'POST',
      body: JSON.stringify({}),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then(async (res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res.length > 0) {
          opcionesTipo(res);
        } else if (res.length < 1) {
          setLoadingSelect(false);
          toast.error('No existen Tipos de Vehículos.');
        }
      })
      .catch((error) => {
        setLoadingSelect(false);
        return toast.error('Error al consultar la información, intente nuevamente');
      });
  };

  const opcionesTipo = (res) => {
    selectOptionsTipo.length = 0;
    res.map((opcion) => {
      if (opcion.status === 'active') {
        return selectOptionsTipo.push({
          value: opcion._id,
          label: opcion.tipo,
        });
      }
    });
    setLoadingSelect(false);
    return selectOptionsTipo;
  };

  const render = () => {
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link to='/requisitos'>Requisitos</Link>
          <Enlace underline='hover' color='text.primary'>
            Crear Requisito
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Crear Requisito</h1>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item md={12} xs={12}>
            <Typography variant='h6'>Datos</Typography>
            <CamposObligatoriosLeyenda />
          </Grid>
          <Grid item md={3} xs={12}>
            <TextField
              label='Titulo'
              variant='filled'
              name='titulo'
              required={siEsRequeridoEnEsquema("titulo",requeridos)}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              label='Descripción'
              variant='filled'
              name='descripcion'
              required={siEsRequeridoEnEsquema("descripcion",requeridos)}
              fullWidth
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <FormControl fullWidth className='mb-3'>
              <InputLabel required={siEsRequeridoEnEsquema("tipo",requeridos)}>Tipo</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Tipo'
                name='tipo'
                onChange={handleChange}
                defaultValue={requisito.status} >
                <MenuItem value='cliente'>Cliente</MenuItem>
                <MenuItem value='empresa'>Empresa</MenuItem>
                <MenuItem value='empresa-cliente'>Empresa versión cliente</MenuItem>
                <MenuItem value='conductor'>Conductor</MenuItem>
                <MenuItem value='vehiculo'>Vehículo</MenuItem>
                <MenuItem value='equipo'>Equipo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {selectVehicle ? (
            <Grid item md={3} xs={12}>
              <FormControl fullWidth className='mb-3'>
                <InputLabel required={siEsRequeridoEnEsquema("tipo_vehiculo",requeridos)}>
                  Tipo de Vehiculo
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Tipo de vehiculo'
                  name='tipo_vehiculo'
                  onChange={handleSelectTipo}
                >
                  <MenuItem value={"todos"}>Todos</MenuItem>
                  {selectOptionsTipo.map((opcion) => {
                    return (
                      <MenuItem value={opcion.value}>{opcion.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ) : (
            ''
          )}
          <Grid item md={12} xs={12}>
            {loading === true ? (
              <CircularProgress />
            ) : (
              <Button
                variant='contained'
                xs={{ m: 0 }}
                onClick={() => crearRequisito()}
              >
                CREAR
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default RequisitosCreate;
