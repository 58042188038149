import { LoadingButton } from "@mui/lab"
import { Alert, Button, CircularProgress, debounce, Grid, MenuItem, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { FaMinusCircle, FaPlus } from "react-icons/fa";
const CotizacionSugerencias = (props) => {
    const estructuraActual = {
        options: [
            { value: "Transporte" },
            { value: "Seguro de carga" },
            { value: "Peonetas" },
        ]
    }
    const [ configuracion, setConfiguracion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const dispatch      = useDispatch()
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const sesion        = useSelector((state) => state.miusuario)
    const showTitle     = props.showTitle ? props.showTitle : false
    const pais = useSelector(state => state.pais)
    const [ id, setId ] = useState("")
    const tipo          = "cotizaciones"
    const sub_tipo      = "sugerencias"

    const handleChange = (e) => {
        const { name, value } = e.target
        return setConfiguracion(prev => {
            const actual = { ...prev }
            actual[name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const obtenerConfiguracion = async () => {
        setLoading(true);
        return fetch(`${urlapi}/configuracion/personalizar`, {
        method: 'POST',
        body: JSON.stringify({
            tipo,
            sub_tipo,
            detalles: estructuraActual
        }),
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${sesion.tokenSession}`,
            'country': pais,
        },
        })
        .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
        })
        .then((res) => {
            console.log(res)
            if (!res) {
                toast.error('Sin datos');
                return setLoading(false);
            } else if (res.errorMessage) {
                toast.error(res.errorMessage);
                return setLoading(false);
            } else if (res._id) {
                setId(res._id)
                setConfiguracion(res.detalles)
            }
            return setLoading(false);
        })
        .catch((error) => {
            toast.error('Error al consultar la información, intente nuevamente');
            return setLoading(false);
        })
    }

    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    useEffect(() => {
        obtenerConfiguracion()
    }, [])

    const handleChangeOption = (e, i) => {
        const { name, value } = e.target
        console.log(name, value, i)
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options[i][name] = value
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const remover = (i) => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options.splice(i, 1)
            actualizar({ _id: id, detalles: actual })
            return actual
        })
    }

    const mostrarSugerencias = () => {
        if(!configuracion.options) return null
        if(!Array.isArray(configuracion.options)) return null
        return <div>
            {
                configuracion.options.map((item, index) => {
                    return <div key={`opt-${index}`}>
                        <Grid container spacing={1} justifyItems="center" alignItems="center" alignContent="center">
                        <Grid item xs={10}>
                        <TextField
                            label='Nombre de la sugerencia'
                            variant='filled'
                            name='value'
                            mb={2}
                            value={item.value}
                            fullWidth
                            onChange={(e) => handleChangeOption(e, index)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                        <Button color="error" startIcon={<FaMinusCircle />} onClick={() => remover(index)} >REMOVER</Button>
                        </Grid>
                        
                        </Grid>
                        </div>
                })
            }
        </div>
    }

    const agregarOpcion = () => {
        setConfiguracion(prev => {
            let actual = { ...prev }
            actual.options.push({ value: "" })
            return actual
        })
    }

    if(loading) return <div><CircularProgress /></div>

    return <div>
        <Typography variant='h4' mt={0} ><b>Sugerencias en títulos de cotización</b></Typography>
        <Typography >Al generar la cotización, los proveedores pueden acceder a opciones preestablecidas para seleccionar en lugar de siempre escribir los títulos de los items.</Typography>
        <Typography mb={2}>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>{ loadingGuardado ? <Alert severity="info"><b>GUARDANDO...</b></Alert> : <Alert severity="success"><b>GUARDADO</b></Alert> }</b> }</Typography>
        <Grid container spacing={1}>
            {/* <Grid item md={6} xs={12}>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Tipo de profit"
                    sx={{ mb: 2 }}
                    fullWidth
                    name="type"
                    defaultValue={configuracion.type}
                    onChange={handleChange}
                    >
                    <MenuItem value="porcentaje">Porcentaje</MenuItem>
                    <MenuItem value="fijo">Monto fijo</MenuItem>
                </TextField>
            </Grid> */}
            <Grid item xs={12}>
                <Button style={{ marginBottom: 10 }} variant="contained" color="primary" startIcon={<FaPlus />} onClick={() => agregarOpcion()} > AGREGAR OPCIÓN</Button>
            {mostrarSugerencias()}
              </Grid>
            <Grid item md={12} xs={12}>
            {/* <LoadingButton
                    sx={{ width: "100%" }}
                    loading={loadingGuardado}
                    loadingPosition="start"
                    variant="outlined"
                    color="success"
                    onClick={() => actualizarConfiguracion()}
                    >
                     GUARDAR CAMBIOS
                </LoadingButton> */}
              </Grid>
        </Grid>
    </div>
}

export default CotizacionSugerencias