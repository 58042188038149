import React from 'react';
import Structure from '../Structure';
import MenuConfig from '../Configuracion/menu';
import { Grid } from '@mui/material';

const ContentConfiguracion = (props) => {
    const {
        component
    } = props

  const render = () => {
    return (
      <div>
        <h1 style={{ margin: 0 }}>Configuración</h1>
        <Grid container spacing={3}>
          <Grid item md={3}>
            <MenuConfig />
          </Grid>
          <Grid item md={9}>
            {component}
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default ContentConfiguracion