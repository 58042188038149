import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SecurityIcon from '@mui/icons-material/Security';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ListadoConductores from '../Drivers/listado';
import ListadoRequisitos from '../Requisitos/listado';
import ListadoVehiculos from '../Vehicles/listado';
import ListadoDeRequisitos from '../RequisitosConfig/listado';
import { useQuery } from '../../lib/helpers/usequery';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import ListadoUsuariosProveedores from '../Proveedores/Usuarios/listado';
import ListadoSedes from './sedes/listado';
import SearchLocationInput from '../../subcomponents/maps/autocompletador';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import BotonEliminar from './boton_eliminar';
import ListadoEventos from '../Eventos/listado';
import ListadoPagos from '../Pagos/listado';
import ConfigClientWeb from '../Configuracion/portal_cliente';

const EmpresaClienteDetalles = () => {
  const dispatch = useDispatch();
  const [ loadingMaster, setLoadingMaster] = useState(true);
  const [ loading, setLoading] = useState(false);
  const [ geoDatosEmpresa, setGeoDatosEmpresa] = useState(false);
  const [ client, setClient] = useState(false);
  const [ loadintAsignacion, setLoadingAsignacion] = useState(false);
  const [ permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const { id } = useParams();
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const query = useQuery()
  const default_tab = query.get("tab")
  const [selectedTab, setSelectedTab] = useState( default_tab ? default_tab : "1")
  const history = useHistory()
  const location = useLocation()
  const requeridos = [
    { value: 'razon_social', label: 'Razón social' },
    { value: 'rut', label: 'Rut' },
    { value: 'status', label: 'Estado' }
  ];

  const handleChangeTab = (e,val) => {
    const current = val.toString()
    const queryParams = new URLSearchParams();
    queryParams.set('tab', current)
    history.push({
      pathname: location.pathname,
      search: queryParams.toString()
    });

    return setSelectedTab(current)
  }


  const obtenerCliente = async () => {
    return fetch(`${data.urlapi}/empresas-clientes?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setClient(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  useEffect(() => {
    obtenerCliente();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    client[name] = value;
    return setClient({ ...{}, ...client });
  };

  const actualizarCliente = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!client[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    client.permisos = permisosOtorgados;
    client.geodata = geoDatosEmpresa
    setLoading(true);
    return fetch(`${data.urlapi}/empresas-clientes`, {
      method: 'PUT',
      body: JSON.stringify(client),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const handleCheck = (e) => {
    const valor = e.target.checked
    const { name } = e.target
    client[name] = valor
    return setClient({ ...{}, ...client });
  }

  const formularioEditar = () => {
    return <Grid container spacing={2} sx={{ mb: 2 }}>
    <Grid item md={12} xs={12}>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <Typography variant='h6'><SettingsIcon sx={{ mb: -0.7 }} /> Detalles</Typography>
          <CamposObligatoriosLeyenda />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Razón social'
            variant='filled'
            name='razon_social'
            required={siEsRequeridoEnEsquema("razon_social",requeridos)}
            fullWidth
            defaultValue={client.razon_social}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <TextField
            label='Rut'
            variant='filled'
            name='id_fiscal'
            required={siEsRequeridoEnEsquema("id_fiscal",requeridos)}
            fullWidth
            defaultValue={client.id_fiscal}
            onChange={handleChange}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <SearchLocationInput 
          default_value={client.direccion}
          onChange={(place) => {
            if(!place) return toast.error("Sin datos de ubicación, escribe de nuevo tu dirección")
            console.log(place)
            const lat = place.geometry.location.lat()
            const lng = place.geometry.location.lng()
            let region = ''
            const buscar_region = place.address_components.findIndex(comp => {
                return comp.types.includes('administrative_area_level_1')
            })
            if(buscar_region > -1) region = place.address_components[buscar_region].long_name
            
            let comuna = ''
            const buscar_comuna = place.address_components.findIndex(comp => {
                return comp.types.includes('administrative_area_level_3')
            })
            if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name
            
            let pais = ''
            const buscar_pais = place.address_components.findIndex(comp => {
                return comp.types.includes('country')
            })
            if(buscar_pais > -1) pais = place.address_components[buscar_pais].long_name

            const lugar = {
                id_empresa: client._id,
                titulo: "Sede principal",
                direccion: place.formatted_address,
                tipo: "sede-principal",
                geocode: place,
                active: true,
                location: {
                  type: "Point",
                  coordinates: [
                    lng,
                    lat
                  ]
                },
                pais_code: pais
            }
            setGeoDatosEmpresa(lugar)
            client.direccion = place.formatted_address
            return setClient(client)
          }} />
        </Grid>
        
        <Grid item md={3} xs={12}>
          <FormControl fullWidth className='mb-3'>
            <InputLabel id='demo-simple-select-label'>Estado</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              label='Estado'
              name='status'
              onChange={handleChange}
              defaultValue={client.status}
            >
              <MenuItem value='active'>Activo</MenuItem>
              <MenuItem value='pending'>Pendiente</MenuItem>
              <MenuItem value='in_process'>En proceso de validación</MenuItem>
              <MenuItem value='trash'>Desactivado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControlLabel  control={<Switch defaultChecked={client.credito} name="credito" onChange={handleCheck} />} label="Habilitar crédito para viajes" />
          {
            client.credito === true ? <TextField
            label='Tope Crédito mensual'
            variant='filled'
            name='credito_mensual'
            type="number"
            required={siEsRequeridoEnEsquema("credito_mensual",requeridos)}
            fullWidth
            defaultValue={client.credito_mensual}
            onChange={handleChange}
          /> : false
          }
        </Grid>
        <Grid item md={12} xs={12}>
          {loading === true ? (
            <CircularProgress />
          ) : (
            <Button
              variant='contained'
              color='success'
              xs={{ m: 0 }}
              onClick={() => actualizarCliente()}
            >
              GUARDAR CAMBIOS
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
  }

  const asignarRequisito = async (_id) => {
    setLoadingAsignacion(true)
    return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${sesion.tokenSession}`,
            'country': pais,
            'lang': idioma
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            toast.success("Agregado exitosamente")
            setTimeout(() => {
                window.location.reload()
            }, 1000);
        }
        return setLoadingAsignacion(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingAsignacion(false)
    })
}

  const seleccionarRequisito = (req) => {
    asignarRequisito(req._id)
  }

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (!client) return <SinData />;
    console.log(client);
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/empresas/clientes'>Empresas - Clientes</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar empresa
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar empresa</h1>
        <BotonEliminar id={id} />
        <TabContext value={selectedTab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList  onChange={handleChangeTab}
                        variant="scrollable"
                        scrollButtons="auto"
                        >
                        <Tab label="Ficha" value="1" />
                        {/* <Tab label="Conductores" value="2" />
                        <Tab label="Vehículos" value="3" /> */}
                        <Tab label="Requisitos" value="4" />
                        <Tab label="Pagos" value="8" />
                        <Tab label="Usuarios" value="5" />
                        <Tab label="Bitácora" value="7" />
                        <Tab label="Configuración Portal Cliente" value="9" />
                        </TabList>
                    </Box>
                    <TabPanel value="1" sx={{ p:1 }}>
                    {formularioEditar()}
                    </TabPanel>
                    {/* <TabPanel value="2" sx={{ p:1 }}>
                      <ListadoConductores condicion_default={{ propietario: client.rut, propietario_autorizado: true }} />
                    </TabPanel>
                    <TabPanel value="3" sx={{ p:1 }}>
                      <ListadoVehiculos condicion_default={{ propietario: client.rut }} />
                    </TabPanel> */}
                    <TabPanel value="4" sx={{ p:1 }}>
                    <h3>Requisitos cargados</h3>
                    <ListadoRequisitos condicion_default={{ id_target: id }} />
                    <Divider light  />
                    <h3>Otros requisitos</h3>
                    { loadintAsignacion ? <CircularProgress /> : <ListadoDeRequisitos tipo="funcion" typeView="card" seleccionarConductor={(data) => seleccionarRequisito(data)} condicion_default={{ tipo: "empresa-cliente" }} /> }
                    <div style={{ marginTop: 20 }}></div>
                    </TabPanel>
                    <TabPanel value="5" sx={{ p:1 }}>
                    <ListadoUsuariosProveedores type_business="empresa-cliente" condicion_default={{ propietario: client.rut }} id_empresa={id} />
                    </TabPanel>
                    <TabPanel value="6" sx={{ p:1 }}>
                      <ListadoSedes id_empresa={client.rut} />
                    </TabPanel>
                    <TabPanel value="7" sx={{ p:1 }}>
                      <ListadoEventos condicion_default={{ id_target: client._id }} />
                    </TabPanel>
                    <TabPanel value="8" sx={{ p:1 }}>
                      <ListadoPagos condicion_default={{ propietario: client.rut }} />
                    </TabPanel>
                    <TabPanel value="9" sx={{ p:1 }}>
                      <ConfigClientWeb propietario={client.rut} />
                    </TabPanel>
                    </TabContext>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default EmpresaClienteDetalles