import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data, { configs3 } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import S3FileUpload from 'react-s3/lib/ReactS3';
import ListadoDeRequisitos from '../RequisitosConfig/listado';
import ListadoRequisitos from '../Requisitos/listado';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CargaArchivo from '../../subcomponents/general/cargaArchivo';
import { rutas } from '../../lib/data/rutas';
import { LoadingButton } from '@mui/lab';
import { FaRegTrashAlt } from 'react-icons/fa';

const TiposCuentaDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loadingTarifa, setLoadingTarifa] = useState(false);
  const [ loadingDelete, setLoadingDelete ] = useState(false)
  const [loading, setLoading] = useState(false);
  const [loadingBtnTarifa, setLoadingBtnTarifa] = useState(false);
  const [tipoVehiculo, setTipoVehiculo] = useState(false);
  const [tarifa, setTarifa] = useState({});
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const { id } = useParams();
  const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  const [value, setValue] = useState(0);
  const [loadingFoto, setLoadingFoto] = useState(0);
  const requeridos = [
    { value: 'titulo', label: 'Titulo' },
    { value: 'status', label: 'Estado' },
  ];
  const requeridos_tarifa = [
    { value: 'precio_km', label: 'Precio kilometro' },
    { value: 'precio_minuto', label: 'Precio minuto' },
    { value: 'precio_base', label: 'Precio base' },
    { value: 'precio_maximo', label: 'Precio maximo' },
    { value: 'tarifa_cancelacion', label: 'Tarifa cancelación' },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (e, value) => {
    return setValue(value);
  };

  const obtenerTipoVehiculo = async () => {
    return fetch(`${data.urlapi}/tiposcuenta?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setTipoVehiculo(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  useEffect(() => {
    obtenerTipoVehiculo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    tipoVehiculo[name] = value;
    return setTipoVehiculo({ ...{}, ...tipoVehiculo });
  };

  const handleChangeTarifa = (e) => {
    const { name, value } = e.target;
    tarifa[name] = value;
    return setTarifa(tarifa);
  };

  const actualizarTipoVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!tipoVehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    tipoVehiculo.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/tiposcuenta`, {
      method: 'PUT',
      body: JSON.stringify(tipoVehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
          setTimeout(() => {
            window.location.url = `tipos-cuenta`
          }, 1000);
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };


  const eliminarValor = async () => {
    
    setLoadingDelete(true);
    return fetch(`${data.urlapi}/tiposcuenta?id=${tipoVehiculo._id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
        } else if (res._id) {
          toast.success('Realizado exitosamente');
          setTimeout(() => {
            window.location = `tipos-cuenta`
          }, 1000);
        }
        return setLoadingDelete(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingDelete(false);
      });
  };

  const crearTarifa = async () => {
    
    let faltantes = [];
    tarifa.id_vehiculo = id;
    requeridos_tarifa.map((campo) => {
      if (!tarifa[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoadingBtnTarifa(true);
    console.log(data.urlapi);
    return fetch(`${data.urlapi}/tarifa`, {
      method: 'POST',
      body: JSON.stringify(tarifa),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingBtnTarifa(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingBtnTarifa(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `/configuration-vehicles-type-${id}`);
          }, 500);
        }
        return setLoadingBtnTarifa(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingBtnTarifa(false);
      });
  };

  const handleImage = (url) => {
    setTipoVehiculo(prev => {
      let actual = { ...prev }
      actual.icon = url
      return actual
    })

}

const seleccionarRequisito = (req) => {
  asignarRequisito(req._id)
}

const asignarRequisito = async (_id) => {
  setLoadingAsignacion(true)
  return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
      method:'GET',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      if(!res){
          toast.error('Sin datos')
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
      } else if(res._id){
          toast.success("Agregado exitosamente")
          setTimeout(() => {
              window.location.reload()
          }, 1000);
      }
      return setLoadingAsignacion(false)
  })
  .catch(error => {
      toast.error("Error al consultar la información, intente nuevamente")
      return setLoadingAsignacion(false)
  })
}

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (loadingTarifa) return <CircularProgress />;
    if (!tipoVehiculo) return <SinData />;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to={rutas.tipos_cuenta.slug}>{rutas.tipos_cuenta.title}</Href>
          <Enlace underline='hover' color='text.primary' style={{ textTransform: "lowercase" }}>Editar</Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0, textTransform: "lowercase" }}>Editar registro</h1>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles del registro</Typography>
                  <CamposObligatoriosLeyenda />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Título'
                  variant='filled'
                  name='titulo'
                  required={siEsRequeridoEnEsquema("titulo",requeridos)}
                  fullWidth
                  defaultValue={tipoVehiculo.titulo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado *'
                    name='status'
                    onChange={handleChange}
                    defaultValue={tipoVehiculo.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item md={12} xs={12}>
                <ButtonGroup>
                <LoadingButton
                    loading={loading}
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarTipoVehiculo()}
                  >
                    GUARDAR CAMBIOS
                  </LoadingButton>
                <LoadingButton loading={loadingDelete} variant='contained' color="error" startIcon={<FaRegTrashAlt size={15} />} onClick={() => eliminarValor()}>ELIMINAR</LoadingButton>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default TiposCuentaDetails;
