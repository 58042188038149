import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Structure from '../Structure';
import { Link as Href, useParams } from 'react-router-dom';
import { Link as Enlace } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import data, { configs3 } from '../../lib/backend/data';
import toast from 'react-hot-toast';
import { cerrarSesion } from '../../redux/actions/session';
import PropTypes from 'prop-types';
import SinData from '../General/sin_data';
import SettingsIcon from '@mui/icons-material/Settings';
import ImageIcon from '@mui/icons-material/Image';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import S3FileUpload from 'react-s3/lib/ReactS3';
import ListadoDeRequisitos from '../RequisitosConfig/listado';
import ListadoRequisitos from '../Requisitos/listado';
import CamposObligatoriosLeyenda from '../../subcomponents/general/formularios/campos_requeridos_leyenda';
import { siEsRequeridoEnEsquema } from '../../lib/helpers/main';
import CargaArchivo from '../../subcomponents/general/cargaArchivo';

const VehicleTypesDetails = () => {
  const dispatch = useDispatch();
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loadingTarifa, setLoadingTarifa] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingBtnTarifa, setLoadingBtnTarifa] = useState(false);
  const [tipoVehiculo, setTipoVehiculo] = useState(false);
  const [tarifa, setTarifa] = useState({});
  const [permisosOtorgados, setPermisosOtorgados] = useState([]);
  const sesion = useSelector((state) => state.miusuario);
  const pais = useSelector(state => state.pais)
  const idioma = useSelector(state => state.idioma)
  const { id } = useParams();
  const [loadintAsignacion, setLoadingAsignacion] = useState(false);
  const [value, setValue] = useState(0);
  const [loadingFoto, setLoadingFoto] = useState(0);
  const requeridos = [
    { value: 'tipo', label: 'Titulo' },
    { value: 'status', label: 'Estado' },
  ];
  const requeridos_tarifa = [
    { value: 'precio_km', label: 'Precio kilometro' },
    { value: 'precio_minuto', label: 'Precio minuto' },
    { value: 'precio_base', label: 'Precio base' },
    { value: 'precio_maximo', label: 'Precio maximo' },
    { value: 'tarifa_cancelacion', label: 'Tarifa cancelación' },
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (e, value) => {
    return setValue(value);
  };

  const obtenerTipoVehiculo = async () => {
    return fetch(`${data.urlapi}/vehiculos/tipo?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingMaster(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingMaster(false);
        } else if (res._id) {
          setTipoVehiculo(res);
        }
        return setLoadingMaster(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingMaster(false);
      });
  };

  const obtenerTarifa = async () => {
    return fetch(`${data.urlapi}/tarifa?id=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          return setLoadingTarifa(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingTarifa(false);
        } else if (res._id) {
          setTarifa(res);
        }
        return setLoadingTarifa(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingTarifa(false);
      });
  };

  useEffect(() => {
    obtenerTipoVehiculo();
    obtenerTarifa();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    tipoVehiculo[name] = value;
    return setTipoVehiculo({ ...{}, ...tipoVehiculo });
  };

  const handleChangeTarifa = (e) => {
    const { name, value } = e.target;
    tarifa[name] = value;
    return setTarifa(tarifa);
  };

  const actualizarTipoVehiculo = async () => {
    
    let faltantes = [];
    requeridos.map((campo) => {
      if (!tipoVehiculo[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);
    tipoVehiculo.permisos = permisosOtorgados;

    setLoading(true);
    return fetch(`${data.urlapi}/vehiculos/tipo`, {
      method: 'PUT',
      body: JSON.stringify(tipoVehiculo),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoading(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoading(false);
        } else if (res._id) {
          toast.success('Actualizado exitosamente');
        }
        return setLoading(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoading(false);
      });
  };

  const crearTarifa = async () => {
    
    let faltantes = [];
    tarifa.id_vehiculo = id;
    requeridos_tarifa.map((campo) => {
      if (!tarifa[campo.value]) faltantes.push(campo.label);
      return true;
    });
    if (faltantes.length > 0)
      return toast.error(`Faltan campos: ${faltantes.join(', ')}`);

    setLoadingBtnTarifa(true);
    console.log(data.urlapi);
    return fetch(`${data.urlapi}/tarifa`, {
      method: 'POST',
      body: JSON.stringify(tarifa),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer: ${sesion.tokenSession}`,
        'country': pais,
        'lang': idioma
      },
    })
      .then((res) => {
        if (res.status === 401) return dispatch(cerrarSesion());
        return res.json();
      })
      .then((res) => {
        if (!res) {
          toast.error('Sin datos');
          return setLoadingBtnTarifa(false);
        } else if (res.errorMessage) {
          toast.error(res.errorMessage);
          return setLoadingBtnTarifa(false);
        } else if (res._id) {
          toast.success('Creado exitosamente');
          setTimeout(() => {
            return (window.location = `/configuration-vehicles-type-${id}`);
          }, 500);
        }
        return setLoadingBtnTarifa(false);
      })
      .catch((error) => {
        toast.error('Error al consultar la información, intente nuevamente');
        return setLoadingBtnTarifa(false);
      });
  };

  const handleImage = (url) => {
    setTipoVehiculo(prev => {
      let actual = { ...prev }
      actual.icon = url
      return actual
    })

}

const seleccionarRequisito = (req) => {
  asignarRequisito(req._id)
}

const asignarRequisito = async (_id) => {
  setLoadingAsignacion(true)
  return fetch(`${data.urlapi}/requisitos/asignar?id=${_id}&target=${id}`, {
      method:'GET',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      if(!res){
          toast.error('Sin datos')
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
      } else if(res._id){
          toast.success("Agregado exitosamente")
          setTimeout(() => {
              window.location.reload()
          }, 1000);
      }
      return setLoadingAsignacion(false)
  })
  .catch(error => {
      toast.error("Error al consultar la información, intente nuevamente")
      return setLoadingAsignacion(false)
  })
}

  const render = () => {
    if (loadingMaster) return <CircularProgress />;
    if (loadingTarifa) return <CircularProgress />;
    if (!tipoVehiculo) return <SinData />;
    return (
      <div>
        <Breadcrumbs aria-label='breadcrumb'>
          <Href to='/configuration-vehicles-type'>Tipos de vehículo</Href>
          <Enlace underline='hover' color='text.primary'>
            Editar tipo de vehículo
          </Enlace>
        </Breadcrumbs>
        <h1 style={{ margin: 0 }}>Editar tipo de vehículo</h1>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item md={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} xs={12}>
                <Typography variant='h6'>
                  <SettingsIcon sx={{ mb: -0.7 }} /> Detalles del registro</Typography>
                  <CamposObligatoriosLeyenda />
              </Grid>
              <Grid item md={3} xs={12}>
                <TextField
                  label='Título'
                  variant='filled'
                  name='tipo'
                  required={siEsRequeridoEnEsquema("tipo",requeridos)}
                  fullWidth
                  defaultValue={tipoVehiculo.tipo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  label='Descripción'
                  variant='filled'
                  name='descripcion'
                  required={siEsRequeridoEnEsquema("descripcion",requeridos)}
                  fullWidth
                  defaultValue={tipoVehiculo.descripcion}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item md={3} xs={12}>
                <FormControl fullWidth className='mb-3'>
                  <InputLabel required={siEsRequeridoEnEsquema("status",requeridos)}>Estado</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Estado *'
                    name='status'
                    onChange={handleChange}
                    defaultValue={tipoVehiculo.status}
                  >
                    <MenuItem value='active'>Activo</MenuItem>
                    <MenuItem value='trash'>Desactivado</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <CargaArchivo onChange={(url) => handleImage(url)} />
                  
                {
                  loadingFoto ? <CircularProgress /> : false
                }
                {
                  tipoVehiculo.icon ? <img style={{ width: 100, display: "block", marginTop: 20 }} src={tipoVehiculo.icon} /> : false
                }
              </Grid>
              <Grid item md={12} xs={12}>
                {loading === true ? (
                  <CircularProgress />
                ) : (
                  <Button
                    variant='contained'
                    color='success'
                    xs={{ m: 0 }}
                    onClick={() => actualizarTipoVehiculo()}
                  >
                    GUARDAR CAMBIOS
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ width: '100%' }}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                textColor='primary'
                indicatorColor='primary'
                aria-label='primary tabs example'
              >
                <Tab
                  value={0}
                  label='Tarifas'
                  {...a11yProps(0)}
                />
                <Tab
                  value={1}
                  label='Requisitos de conductor'
                  {...a11yProps(0)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Typography variant='h6' mb={0}>Formulario de tarifa</Typography>
              <CamposObligatoriosLeyenda />
              <Grid container spacing={2}>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Precio kilómetro'
                    variant='filled'
                    name='precio_km'
                    required={siEsRequeridoEnEsquema("precio_km",requeridos_tarifa)}
                    fullWidth
                    defaultValue={tarifa.precio_km}
                    onChange={handleChangeTarifa}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Precio minuto'
                    variant='filled'
                    name='precio_minuto'
                    required={siEsRequeridoEnEsquema("precio_minuto",requeridos_tarifa)}
                    fullWidth
                    defaultValue={tarifa.precio_minuto}
                    onChange={handleChangeTarifa}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Precio base'
                    variant='filled'
                    name='precio_base'
                    required={siEsRequeridoEnEsquema("precio_base",requeridos_tarifa)}
                    fullWidth
                    defaultValue={tarifa.precio_base}
                    onChange={handleChangeTarifa}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Precio máximo'
                    variant='filled'
                    name='precio_maximo'
                    required={siEsRequeridoEnEsquema("precio_maximo",requeridos_tarifa)}
                    fullWidth
                    defaultValue={tarifa.precio_maximo}
                    onChange={handleChangeTarifa}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Tarifa cancelación'
                    variant='filled'
                    name='tarifa_cancelacion'
                    required={siEsRequeridoEnEsquema("tarifa_cancelacion",requeridos_tarifa)}
                    fullWidth
                    defaultValue={tarifa.tarifa_cancelacion}
                    onChange={handleChangeTarifa}
                  />
                </Grid>
                <Grid item md={3} xs={12}>
                  <TextField
                    label='Profit (Porcentaje)'
                    variant='filled'
                    name='profit'
                    required={siEsRequeridoEnEsquema("profit",requeridos_tarifa)}
                    type="number"
                    fullWidth
                    defaultValue={tarifa.profit}
                    onChange={handleChangeTarifa}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {loadingBtnTarifa === true ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      variant='contained'
                      color='success'
                      xs={{ m: 0 }}
                      onClick={() => crearTarifa()}
                    >
                      GUARDAR CAMBIOS
                    </Button>
                  )}
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
            <Typography variant='h6' mb={2} style={{ marginTop: 0 }}>Requisitos de conductor requeridos para ser asignado a este vehículo</Typography>
                        <ListadoRequisitos hideRequiredMessage={true} hideStatusText={true} hideDetailButton={true} condicion_default={{ id_target: id }} />
                        <Divider sx={{ mt:4, mb: 4 }} />
            <Typography variant='h6' mb={2} style={{ marginTop: 0 }}>Asigna uno o varios de los siguientes requisitos de conductor</Typography>
              <ListadoDeRequisitos tipo="funcion" typeView="card" seleccionarConductor={(data) => seleccionarRequisito(data)} condicion_default={{ tipo: "conductor" }} />
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    );
  };

  return <Structure component={render()} />;
};

export default VehicleTypesDetails;
