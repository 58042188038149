import { Button, ButtonGroup, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { style, urlapi } from "../../lib/backend/data";
import CargaArchivo from "../../subcomponents/general/cargaArchivo";
import { FaFileAlt } from "react-icons/fa";

const Factura = (props) => {
    const {
        pago
    } = props
    const id_pago = pago?._id
    const [ loading, setLoading ] = useState(false)
    const [ facturas, setFacturas ] = useState([])
    const sesion = useSelector((state) => state.miusuario);
    const dispatch = useDispatch()


    const obtenerDatos = async ()=>{
        setLoading(true)
        return fetch(`${urlapi}/pagos/facturas?idpago=${id_pago}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.datos)){
                setFacturas(res.datos)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if(props.onChange) props.onChange(name, value)
    }

    const mostrarCarga = () => {
        if(!pago?.folio_factura) return false
        if(pago?.url_factura) return <div>
            <ButtonGroup>
            <Button variant="outlined" size="medium" ><a href={pago?.url_factura} target="_blank"><FaFileAlt color={style.primary} size={15} className="hover" /></a></Button>
            <Button
                variant="contained"
                onClick={() => handleChange({ target: { name: "url_factura", value: "" } })}
            >Eliminar archivo</Button>
            </ButtonGroup>
        </div>
        return <div>
            <CargaArchivo title="ASOCIAR ARCHIVO" onChange={(url) => handleChange({ target: { name: "url_factura", value: url } }) } />
        </div>
    }

    return <div>
        <TextField
        sx={{ mb: 2 }}
                              label='Folio'
                              placeholder="Escribe aquí el folio de la factura"
                              variant='filled'
                              name='folio_factura'
                              fullWidth
                              value={pago?.folio_factura}
                              onChange={handleChange}
                              helperText="Ingresa un folio de factura emitida."

                            />
        {mostrarCarga()}
    </div>
}

export default Factura;