const obtenerIdentificadorVehiculo = (driver) => {
    const default_texto = 'Sin información'
    let texto = ''
    if(!driver) return default_texto
    if(typeof driver !== 'object') return default_texto
    if(driver.tipo) texto = driver.tipo
    if(driver.modelo) texto = `${texto} ${driver.modelo}`
    if(driver.marca) texto = `${texto} ${driver.marca}`
    if(driver.patente) texto = `${texto} ${driver.patente}`
    if(driver.ano) texto = `${texto} ${driver.ano}`
    return texto
}

module.exports = {
    obtenerIdentificadorVehiculo
}