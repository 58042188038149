const { DateTime } = require("luxon");
const { timezone } = require("../backend/data");

const formatDateHoy = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

const formatMes = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return month;
};

const formatDateMonth = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month].join('-');
};

const formatDateYear = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year].join('-');
};

const addMoreDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const restDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
};

const formatDateHoyFromDate = (date) => {
  var d = addMoreDays(new Date(date), 1),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

const configuracion_fechas = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const configuracion_horas = {
  timeZone: 'America/Santiago',
  hour: '2-digit',
  minute: '2-digit',
};

const StringMes = (e) => {
  let string = '';

  if (e === '01') {
    string = 'Enero';
  } else if (e === '02') {
    string = 'Febrero';
  } else if (e === '03') {
    string = 'Marzo';
  } else if (e === '04') {
    string = 'Abril';
  } else if (e === '05') {
    string = 'Mayo';
  } else if (e === '06') {
    string = 'Junio';
  } else if (e === '07') {
    string = 'Julio';
  } else if (e === '08') {
    string = 'Agosto';
  } else if (e === '09') {
    string = 'Septiembre';
  } else if (e === '10') {
    string = 'Octubre';
  } else if (e === '11') {
    string = 'Noviembre';
  } else if (e === '12') {
    string = 'Diciembre';
  }

  return string;
};

const formatDateAno = (date) => {
  var d = new Date(date),
    year = d.getFullYear();
  return year;
};

/**
 * @param {int} The month number, 0 based
 * @param {int} The year, not zero based, required to account for leap years
 * @return {Date[]} List with date objects for each day of the month
 */
const getDaysInMonth = (month, year) => {
  var days = Array.from(Array(new Date().getDate()), (e, i) => i + 1);
  return days;
};

const getLastAndFirstDayWeek = () => {
  var curr = new Date; // get current date
  var first = curr.getDate() - curr.getDay();
  var last = first + 6; // last day is the first day + 6

  var firstday = new Date(curr.setDate(first)).toLocaleDateString("fr-CA");
  var lastday = new Date(curr.setDate(last)).toLocaleDateString("fr-CA");

  return { firstday, lastday }
}

const obtenerFechaHoraZonaHorariaLocal = () => {
  const now = DateTime.now()
  now.setZone(timezone)
  const fecha_local = now.toISO()
  return fecha_local
}

const dateToYMDFormat = (fecha) => {
  if(fecha){
      if(typeof fecha === "string"){
          return DateTime.fromISO(fecha).toFormat("yyyy-MM-dd")
      } else {
          return DateTime.fromJSDate(fecha).toFormat("yyyy-MM-dd")
      }
  } else {
      return DateTime.now().toFormat("yyyy-MM-dd")
  }
}


module.exports = {
  obtenerFechaHoraZonaHorariaLocal,
  formatDateHoy,
  formatMes,
  getLastAndFirstDayWeek,
  formatDateMonth,
  formatDateYear,
  addMoreDays,
  getDaysInMonth,
  restDays,
  formatDate,
  formatDateAno,
  StringMes,
  configuracion_fechas,
  configuracion_horas,
  formatDateHoyFromDate,
  dateToYMDFormat
};
