export const rutas = {
    equipments:{
        slug: "/equipments",
        title: "Equipos"
    },
    contacts:{
        slug: "/contacts",
        title: "Contactos"
    },
    destinatarios:{
        slug: "/destinatarios",
        title: "Destinatarios"
    },
    bodegas:{
        slug: "/bodegas",
        title: "Bodegas"
    },
    custom_fields:{
        slug: "/custom-fields",
        title: "Campos personalizados"
    },
    categories:{
        slug: "/categories",
        title: "Categorías"
    },
    service_types:{
        slug: "/service-types",
        title: "Tipos de servicio"
    },
    productos:{
        slug: "/productos",
        title: "Productos"
    },
    costos:{
        slug: "/costos",
        title: "Costos"
    },
    estados_carga:{
        slug: "/protocols",
        title: "Protocolos de entrega"
    },
    ordenes:{
        slug: "/ordenes",
        title: "Viajes"
    },
    reportes_viajes:{
        slug: "/viajes-reporte",
        title: "Reporte"
    },
    vehicles_brands:{
        slug: "/brands-vehicles",
        title: "Marcas"
    },
    vehicles_brands_new:{
        slug: "/brands-vehicles-new",
        title: "Crear marca"
    },
    tipos_cuenta:{
        slug: "/tipos-cuenta",
        title: "Tipos de cuenta"
    },
}