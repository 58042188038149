import CargandoTabla from "./cargando"
import { Box, Button, Grid, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useEffect, useState } from "react"
import data from '../../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../redux/actions/session"
import { fechaATexto } from "../../../lib/helpers/helpers"
import { Link } from "react-router-dom"

const ListadoUsuariosProveedores = (props) => {
    const {
        type_business = "proveedor",
        id_empresa
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerConductores(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination  sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        const url = type_business === "proveedor" ? `${data.urlapi}/usuarios/proveedores/list` : `${data.urlapi}/usuarios/empresas-clientes/list`
        const payload = type_business === "proveedor" ? {
            id_empresa,
            type_business,
            pagina: page
        } : { page, condicion }
        return fetch(url,{
            method:'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!campoBusqueda) return toast.error('Escribe un valor')
        const condicion = { $text: { $search: campoBusqueda.toLowerCase() }, activo: true }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerConductores(1, condicion)
    }

    const seleccionarConductor = (driver) => {
        if(props.seleccionarConductor) props.seleccionarConductor(driver)
        return false
    }

    const showByType = (tipo, driver) => {
        switch (tipo) {
            case "funcion":
                return <b onClick={() => seleccionarConductor(driver)}>{driver.nombres}</b>
            case "link":
                return <b><Link to={`/empresas-clientes/user-${driver._id}`}>{driver.nombres}</Link></b>
            default:
                return 
        }
    }

    const obtenerMetaValor = (meta, key) => {
        if(!meta) return ""
        if(Array.isArray(meta) !== true) return ""
        if(meta.length < 1) return ""
        const i = meta.findIndex(e => e.key === key)
        if(i > -1) return meta[i].value
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    if(loadingConductores) return <CargandoTabla />

    return <div>
            <Grid container >
                <Grid item xs={12}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1, mb: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField size="small" id="outlined-basic" defaultValue={campoBusqueda} label="Buscar por nombre, apellido, email o móvil" variant="outlined" onChange={handleChangeCampo} fullWidth />
                            </Grid>
                            <Grid item xs={3}>
                                <Button type="submit" size="large" variant="contained" >BUSCAR</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
        
        <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Nombres</TableCell>
                <TableCell>Apellidos</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Móvil</TableCell>
                <TableCell>Cargo</TableCell>
                <TableCell>Registro</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            { conductores.map((driver) => (
                <TableRow
                key={driver.email}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell>{showByType(tipo, driver)}</TableCell>
                <TableCell>{driver.apellidos}</TableCell>
                <TableCell>{driver.email}</TableCell>
                <TableCell>{driver.codigo_movil}{driver.movil}</TableCell>
                <TableCell>{obtenerMetaValor(driver.meta, "cargo").toString()}</TableCell>
                <TableCell>{fechaATexto(driver.createdAt)}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    </div>
}

export default ListadoUsuariosProveedores