import { Box, Button, Card, CardActions, CardContent, CircularProgress, debounce, Dialog, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Pagination, Paper, Select, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { useCallback, useEffect, useState } from "react"
import data, { urlapi } from '../../lib/backend/data'
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { fechaATexto } from "../../lib/helpers/helpers"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CargandoTabla from '../Requisitos/cargando'
import { IoIosHelpCircle } from 'react-icons/io'
import Factura from './factura'
import { FaFileAlt } from 'react-icons/fa'
import { formatoMoneda } from '../../lib/helpers/main'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });



const ListadoPagos = (props) => {
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [ vehiculos, setVehiculos] = useState([])
    const [ showModal, setShowModal] = useState(false)
    const [ currentRequisito, setCurrentRequisito] = useState(false)
    const [ loadingVehiculos, setLoadingVehiculos] = useState([])
    const [ loadingCambios, setLoadingCambios] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ idLoading, setIdLoading ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const trash = props.trash ? props.trash : false
    const show_filter = props.show_filter ? props.show_filter : false
    const titulo = props.title ? props.title : 'registros'
    const statuses = [
        {
            value: "pending",
            label: "Pendiente",
            info: "El pago está pendiente de ser procesado, probablemente por falta de confirmación de la transacción."
        },
        {
            value: "confirmed",
            label: "Confirmado",
            info: "El pago está confirmado"
        }
    ]

    const stringByStatusPayment = (status) => {
        const indexar = statuses.reduce((acc, item) => {
            acc[item.value] = item.label
            return acc
        }, {})
        return indexar[status] ? indexar[status] : status
    }
    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerRequisitos(page)
    }
    
    const handleChangePagina = (e,page) => {
        setPagina(page)
        return obtenerRequisitos(page, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div className="mb-3">
            <Pagination sx={{ mt:2 }} color="primary" count={cantidad} page={pagina} onChange={handleChangePagina} />
        </div>
    }

    const obtenerRequisitos = async (page, query)=>{
        setLoadingVehiculos(true)
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/pagos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingVehiculos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingVehiculos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setVehiculos(res.datos)
                setTotal(res.total)
            }
            return setLoadingVehiculos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingVehiculos(false)
        })
    }

    useEffect(() => {
        obtenerRequisitos(1, false)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        const cond = props.condicion_default ? props.condicion_default : {}
        if(!campoBusqueda) return obtenerRequisitos(1, cond)
        const condicion = {...cond, $text: { $search: campoBusqueda.toLowerCase() } }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerRequisitos(1, condicion)
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target
        return setCampoBusqueda(value)
    }

    const filtro = () => {
        if(!show_filter) return false
        return <div>
            <Grid container >
                <Grid item xs={12}>
                    <Box component="form" noValidate onSubmit={handleSubmit}  sx={{ mt: 1, mb: 1 }}>
                        <TextField id="outlined-basic" label="Buscar por nombre, apellido, email o móvil" variant="outlined" onChange={handleChangeCampo} fullWidth />
                    </Box>
                </Grid>
            </Grid>
        </div>
    }

    const obtenerRegistro = (id) => {
        if(idLoading !== false) return
        setIdLoading(id)
        return fetch(`${data.urlapi}/requisitos?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setIdLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setIdLoading(false)
            } else if(res._id){
                setCurrentRequisito(prev => ({...{}, ...res}))
                setShowModal(true)
            }
            return setIdLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdLoading(false)
        })
    }

    const handleModalRF = () => {
        return setShowModal(false)
    }

    const guardarCambios = async (data) => {
        setLoadingCambios(true)
        return fetch(`${urlapi}/pagos`,{
            method:'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingCambios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCambios(false)
            } else if(res._id){
                toast.success('Actualizado exitosamente')    

                setVehiculos(prev => {
                    let actual = [ ...prev ]
                    const i  = actual.findIndex(v => v._id === res._id)
                    if(i > -1){
                        actual[i] = res
                    }
                    return actual
                })

            }
            return setLoadingCambios(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCambios(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setCurrentRequisito(prev => {
            let actual = { ...prev }
            actual[name] = value
            onChangeFactura(name, value)
            return actual
        })
    }

    const abrirPago = (data) => {
        setCurrentRequisito(data)
        setShowModal(true)
    }

    const ejecutar = () => {
        let data = {}
        data.map(e => e)
    }

    const guardarAutomaticamente = useCallback(debounce((data) => guardarCambios(data), 500), [])

    const onChangeFactura = (name, value) => {
        setCurrentRequisito(prev => {
            let actual = { ...prev }
            actual[name] = value
            guardarAutomaticamente(actual)
            return actual
        })
    }

    const ModalRegistrosFotograficos = () => {
        let titulo = 'No disponible'
        if(!showModal) return false

        const data = currentRequisito
        return <Dialog
            open={showModal}
            fullWidth={true}
            TransitionComponent={Transition}
            keepMounted
            maxWidth="md"
            onClose={()=>handleModalRF()}
            aria-describedby="alert-dialog-slide-description"
        >
        <DialogTitle id="alert-dialog-slide-description">Detalle de la transacción</DialogTitle>
        <DialogContent>
        <Box className="mb-3">
            <Typography variant="subtitle1">Fecha de la operación {fechaATexto(data.createdAt)}</Typography>
            </Box>
            <Grid container spacing={2}>
            <Grid item md={12}>
                <Typography variant="h5">Referencia</Typography>
                <Typography>{data.titulo}</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography variant="h5">Identificador de viaje</Typography>
                <Typography variant="h3">{data.internal_id}</Typography>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item md={6}>
                <Typography variant="h5">Moneda</Typography>
                <Typography>CLP</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant="h5">Monto</Typography>
                <Typography>{formatoMoneda(data.monto)}</Typography>
            </Grid>
            <Grid item md={12}>
                <Typography  variant="h5">Estado del pago</Typography>
                <FormControl sx={{ mt:2 }} fullWidth className='mb-3'>
                <InputLabel id="demo-simple-select-label" required={true}>Estado del pago</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={`Unidad de medida longitud *`}
                    name="status"
                    onChange={handleChange}
                    defaultValue={data.status}
                    >
                    <MenuItem value="">Selecciona</MenuItem>
                    {
                        statuses.map((item, index) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    }
                </Select>
                </FormControl>
                {/* <Typography>
                <Tooltip title={infoByStatusPayment(data.status)}>
                    {stringByStatusPayment(data.status)} <IoIosHelpCircle />
                </Tooltip>
                </Typography> */}
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
                <Typography variant="h5">Factura asociada</Typography>

                {
                    data.folio_factura ? <Typography sx={{ mb:2 }}>
                    <Tooltip title="Factura asociada, agregaste un folio de factura a este pago, puedes asociar un mismo folio a más de un pago">
                        Asociada <IoIosHelpCircle />
                    </Tooltip>
                    </Typography> : <Typography sx={{ mb:2 }}>
                    <Tooltip title="Sin factura asociada, una vez facturado verás aquí el documento fiscal emitido, esto podría demorar dependiendo del país de facturación">
                        Pendiente <IoIosHelpCircle />
                    </Tooltip>
                    </Typography>
                }
                
            </Grid>
            </Grid>
            <Box sx={{ }}><Factura pago={data} onChange={(name, value) => onChangeFactura(name, value)} /></Box>
            { loadingCambios ? <LinearProgress /> : false }
        </DialogContent>
        </Dialog>
    }
    

    const infoByStatusPayment = (status) => {
        const indexar = statuses.reduce((acc, item) => {
            acc[item.value] = item.info
            return acc
        }, {})
        return indexar[status] ? indexar[status] : status
    }

    const mostrarRegistros = () => {
        if(loadingVehiculos) return <Box sx={{ mt:2 }}><CargandoTabla /></Box>

        if(vehiculos.length < 1) return <h3>No hay registros</h3>
        
        return <div>
            <Grid container >
                <Grid item xs={6}>{paginacion(data.pagina, total)}</Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}><h3 sx={{ m:0 }} >{total} {titulo}</h3></Grid>
            </Grid>
            <TableContainer component={Paper} sx={{ mt:2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="sticky table" stickyHeader >
            <TableHead>
            <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Comentario</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Documento asociado</TableCell>
                <TableCell>Folio factura</TableCell>
                <TableCell>Con crédito</TableCell>
                <TableCell>Estado</TableCell>
                <TableCell>Estado facturación</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            { vehiculos.map((data) => (
                <TableRow
                key={data._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onClick={(() => abrirPago(data))}
                >
                    <TableCell>{fechaATexto(data.createdAt)}</TableCell>
                    <TableCell>{data.titulo}</TableCell>
                    <TableCell>{data.monto}</TableCell>
                    <TableCell>{data.url_factura ? <a href={data.url_factura} target="_blank"><FaFileAlt color="black" size={25} className="hover" /></a> : "SIN INFORMACIÓN" }</TableCell>
                    <TableCell>{data.folio_factura ? data.folio_factura : "SIN INFORMACIÓN" }</TableCell>
                    <TableCell>{data.credit === true ? "SI" : "NO"}</TableCell>
                    <TableCell>{infoByStatusPayment(data.status)}</TableCell>
                    <TableCell>{ data.folio_factura ? "Facturado" : "Sin factura asociada" }</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </div>
    }

    const handleChangeFilter = (name, value) => {
        setCondicionBusqueda(prev => {
            let actual = { ...prev}
            if(value === null) delete actual[name]
            if(value === "") delete actual[name]
            else actual[name] = value
            const cond = props.condicion_default ? props.condicion_default : {}
            obtenerRequisitos(1, { ...actual, ...cond })
            return actual
        })
    }

    const filtros = () => {
        return <div>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                <Box
                    component='form'
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1, mb: 1 }}
                >
                    <Grid container spacing={2}>
                                    <Grid item xs={8}>
                    <TextField
                    id='outlined-basic'
                    label='Buscar'
                    variant='outlined'
                    size='small'
                    defaultValue={campoBusqueda}
                    onChange={handleChangeCampo}
                    fullWidth
                    />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button fullWidth type="submit" size="large" variant="contained" >BUSCAR</Button>
                                    </Grid>
                                </Grid>
                </Box>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={3}>
                <FormControl sx={{ mt:2 }} fullWidth className='mb-3'>
                <InputLabel id="demo-simple-select-label" required={true}>Estado del pago</InputLabel>
                <Select
                    
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={`Unidad de medida longitud *`}
                    name="status"
                    onChange={(e) => handleChangeFilter('status', e.target.value)}
                    >
                    <MenuItem value="">Selecciona</MenuItem>
                    {
                        statuses.map((item, index) => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    }
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={3}>
                <FormControl sx={{ mt:2 }} fullWidth className='mb-3'>
                <InputLabel id="demo-simple-select-label" required={true}>Estado de facturación</InputLabel>
                <Select
                    label={`Estado de facturación`}
                    name="status"
                    onChange={(e) => {
                        const valor = e.target.value
                        let value = null
                        if(valor === 'si') value = { $nin: [null, ""] }
                        if(valor === 'no') value = { $in: [null, ""] }
                        handleChangeFilter('folio_factura', value)
                    }}
                    >
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="si">Facturado</MenuItem>
                    <MenuItem value="no">No Facturado</MenuItem>
                </Select>
                </FormControl>
                </Grid>
                <Grid item xs={3}>
                <FormControl sx={{ mt:2 }} fullWidth className='mb-3'>
                <InputLabel id="demo-simple-select-label" required={true}>Con crédito</InputLabel>
                <Select
                    label={`Con crédito`}
                    name="credito"
                    onChange={(e) => {
                        const valor = e.target.value
                        let value = null
                        if(valor === 'si') value = true
                        if(valor === 'no') value = false
                        handleChangeFilter('credit', value)
                    }}
                    >
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="si">SI</MenuItem>
                    <MenuItem value="no">NO</MenuItem>
                </Select>
                </FormControl>
                </Grid>
            </Grid>
        </div>
    }

    return <div>
        {filtro()}
        {ModalRegistrosFotograficos()}
        {filtros()}
        {mostrarRegistros()}
    </div>
}

export default ListadoPagos